import request from "./request"

export function getAddress(query) {
  return request({
    url: "https://restapi.amap.com/v3/geocode/regeo?key=bd3e887ea240b03ad9cea1314e1bda30",
    method: "get",
    params: query
  })
}

export function savePro(query) {
  return request({
    url: "api/web/projectNew/saveOrUpdateProject ",
    method: "post",
    data: query
  })
}


export function getYgzjrl(query) {
  return request({
    url: "api/web/config/getYgzjrl",
    method: "get",
    params: query
  })
}

export function getPoints(query) {
  return request({
    url: "api/web/projectNew/findCoordinates",
    method: "get",
    params: query,
  })
}


export function uploadFile(query) {
  return request({
    url: "api/web/videoUpload/upload",
    method: "post",
    data: query,
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8"
    }
  })
}
