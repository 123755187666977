import axios from "axios";
const service = axios.create({
  timeout: 20000,
  // baseURL: "http://8.134.188.131:8001",
  headers: {
    "Content-Type": "application/json"
  }
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  console.log("经过请求拦截了", config);
  // 请求拦截进来调用显示loading效果
  return config;
}, error => {
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  console.log(response);
  return response;
}, error => {
  console.log("经过响应错误拦截了");
  showNotify({
    type: 'danger',
    message: '服务器错误'
  });
  return Promise.reject(error);
});
export default service;