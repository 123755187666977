import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { onBeforeMount, ref } from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import viewMap from "./components/viewMap.vue";
export default {
  __name: 'App',
  setup(__props) {
    let isView = ref(false);
    let str = window.location.href.split("#")[0];
    let params = new URL(str).searchParams || "";
    isView.value = params.get("isView") || false;
    console.log("当前地址", str);
    return (_ctx, _cache) => {
      return _unref(isView) ? (_openBlock(), _createBlock(viewMap, {
        key: 0
      })) : (_openBlock(), _createBlock(HelloWorld, {
        key: 1
      }));
    };
  }
};